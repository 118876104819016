import JPG_ONE from "../../../assets/images/raster/pages/online-arbitrage/Find/screenshot-1.jpg";
import JPG_ONE_2x from "../../../assets/images/raster/pages/online-arbitrage/Find/screenshot-1@2x.jpg";
import WEBP_ONE from "../../../assets/images/raster/pages/online-arbitrage/Find/screenshot-1.webp";
import WEBP_ONE_2x from "../../../assets/images/raster/pages/online-arbitrage/Find/screenshot-1@2x.webp";
import JPG_ONE_992 from "../../../assets/images/raster/pages/online-arbitrage/Find/screenshot-1@992.jpg";
import JPG_ONE_992_2x from "../../../assets/images/raster/pages/online-arbitrage/Find/screenshot-1@992_2x.jpg";
import WEBP_ONE_992 from "../../../assets/images/raster/pages/online-arbitrage/Find/screenshot-1@992.webp";
import WEBP_ONE_992_2x from "../../../assets/images/raster/pages/online-arbitrage/Find/screenshot-1@992_2x.webp";
import JPG_ONE_mob from "../../../assets/images/raster/pages/online-arbitrage/Find/screenshot-1@mob.jpg";
import JPG_ONE_mob_2x from "../../../assets/images/raster/pages/online-arbitrage/Find/screenshot-1@mob_2x.jpg";
import WEBP_ONE_mob from "../../../assets/images/raster/pages/online-arbitrage/Find/screenshot-1@mob.webp";
import WEBP_ONE_mob_2x from "../../../assets/images/raster/pages/online-arbitrage/Find/screenshot-1@mob_2x.webp";

import PNG_TWO from "../../../assets/images/raster/pages/online-arbitrage/Find/oa-sec6-2.png";
import PNG_TWO_2x from "../../../assets/images/raster/pages/online-arbitrage/Find/oa-sec6-2@2x.png";
import PNG_TWO_Mob from "../../../assets/images/raster/pages/online-arbitrage/Find/oa-sec6-2@mob.png";
import PNG_TWO_Mob2x from "../../../assets/images/raster/pages/online-arbitrage/Find/oa-sec6-2@mob2x.png";
import WEBP_TWO from "../../../assets/images/raster/pages/online-arbitrage/Find/oa-sec6-2.webp";
import WEBP_TWO_2x from "../../../assets/images/raster/pages/online-arbitrage/Find/oa-sec6-2@2x.webp";
import WEBP_TWO_Mob from "../../../assets/images/raster/pages/online-arbitrage/Find/oa-sec6-2@mob.webp";
import WEBP_TWO_Mob2x from "../../../assets/images/raster/pages/online-arbitrage/Find/oa-sec6-2@mob2x.webp";

import PNG_THREE from "../../../assets/images/raster/pages/online-arbitrage/Find/oa-sec6-3.png";
import PNG_THREE_2x from "../../../assets/images/raster/pages/online-arbitrage/Find/oa-sec6-3@2x.png";
import PNG_THREE_Mob from "../../../assets/images/raster/pages/online-arbitrage/Find/oa-sec6-3@mob.png";
import PNG_THREE_Mob2x from "../../../assets/images/raster/pages/online-arbitrage/Find/oa-sec6-3@mob2x.png";
import WEBP_THREE from "../../../assets/images/raster/pages/online-arbitrage/Find/oa-sec6-3.webp";
import WEBP_THREE_2x from "../../../assets/images/raster/pages/online-arbitrage/Find/oa-sec6-3@2x.webp";
import WEBP_THREE_Mob from "../../../assets/images/raster/pages/online-arbitrage/Find/oa-sec6-3@mob.webp";
import WEBP_THREE_Mob2x from "../../../assets/images/raster/pages/online-arbitrage/Find/oa-sec6-3@mob2x.webp";

const FIND_CONTENT = {
  firstScreenshot: {
    JPG: JPG_ONE,
    JPG_2x: JPG_ONE_2x,
    WEBP: WEBP_ONE,
    WEBP_2x: WEBP_ONE_2x,
    JPG_992: JPG_ONE_992,
    JPG_992_2x: JPG_ONE_992_2x,
    WEBP_992: WEBP_ONE_992,
    WEBP_992_2x: WEBP_ONE_992_2x,
    JPG_mob: JPG_ONE_mob,
    JPG_mob2x: JPG_ONE_mob_2x,
    WEBP_mob: WEBP_ONE_mob,
    WEBP_mob2x: WEBP_ONE_mob_2x
  },
  secondScreenshot: {
    WEBP: WEBP_TWO,
    WEBP_2x: WEBP_TWO_2x,
    PNG: PNG_TWO,
    PNG_2x: PNG_TWO_2x,
    WEBP_Mob: WEBP_TWO_Mob,
    WEBP_Mob2x: WEBP_TWO_Mob2x,
    PNG_Mob: PNG_TWO_Mob,
    PNG_Mob2x: PNG_TWO_Mob2x
  },
  thirdScreenshot: {
    WEBP: WEBP_THREE,
    WEBP_2x: WEBP_THREE_2x,
    PNG: PNG_THREE,
    PNG_2x: PNG_THREE_2x,
    WEBP_Mob: WEBP_THREE_Mob,
    WEBP_Mob2x: WEBP_THREE_Mob2x,
    PNG_Mob: PNG_THREE_Mob,
    PNG_Mob2x: PNG_THREE_Mob2x
  }
};
export default FIND_CONTENT;
