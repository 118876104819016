import React from "react";
import SVG_PointIcon from "../../../assets/images/vector/components/common/group-copy.svg";

const QUICK_PICK_CONTENT = {
  listTexts: [
    <><b>Quickly disregard products</b> with high competition, low margins, or any selling limitations</>,
    <><b>Evaluate thousands of items</b> your Wholesale supplier offers you up to 5 times faster</>,
    <><b>Minimize your employee costs</b> to focus your budget on scaling your business</>,
    <><b>Discover if Amazon, FBA or FBM</b> sellers sell the product to know your competition</>,
  ],
  SVG_PointIcon,
};
export default QUICK_PICK_CONTENT;
