import React from "react";
import PG_OA_HOME_INTRO_CONTENT from "./index.content";

const HomeIntroPoints = () => {
  const { paragraphList, SVG_PointIcon } = PG_OA_HOME_INTRO_CONTENT.points;
  return (
    <div className="PgOA-HomeIntro-points">
      {paragraphList.map((text, i) => (
        <div key={i} className="PgOA-HomeIntro-points__item">
          <img src={SVG_PointIcon} alt="" />
          <div>{text}</div>
        </div>
      ))}
    </div>
  );
};

export default HomeIntroPoints;
