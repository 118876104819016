import React from "react";

const Screenshot = ({ customClasses, listPictures }) => {
  const {
    WEBP,
    WEBP_2x,
    PNG,
    PNG_2x,
    WEBP_Mob,
    WEBP_Mob2x,
    PNG_Mob,
    PNG_Mob2x,
  } = listPictures;
  return (
    <div className={customClasses}>
      <picture>
        <source
          type="image/webp"
          media="(min-width: 1200px)"
          srcSet={`${WEBP} 1x, ${WEBP_2x} 2x`}
        />
        <source
          media="(min-width: 1200px)"
          srcSet={`${PNG} 1x, ${PNG_2x} 2x`}
        />
        <source
          type="image/webp"
          media="(min-width: 768px) and (max-width: 1199px)"
          srcSet={`${WEBP_Mob2x} 1x, ${WEBP} 2x`}
        />
        <source
          media="(min-width: 768px) and (max-width: 1199px)"
          srcSet={`${PNG_Mob2x} 1x, ${PNG} 2x`}
        />
        <source
          type="image/webp"
          media="(max-width: 767px)"
          srcSet={`${WEBP_Mob} 1x, ${WEBP_Mob2x} 2x`}
        />
        <source
          media="(max-width: 767px)"
          srcSet={`${PNG_Mob} 1x, ${PNG_Mob2x} 2x`}
        />
        <img src={PNG} alt="" loading="lazy" />
      </picture>
    </div>
  );
};
export default Screenshot;
