import React from "react";
import JPG from "../../../assets/images/raster/pages/online-arbitrage/Study/screenshot.jpg";
import JPG_2x from "../../../assets/images/raster/pages/online-arbitrage/Study/screenshot@2x.jpg";
import JPG_1200 from "../../../assets/images/raster/pages/online-arbitrage/Study/screenshot@1200.jpg";
import JPG_1200_2x from "../../../assets/images/raster/pages/online-arbitrage/Study/screenshot@1200_2x.jpg";
import JPG_992 from "../../../assets/images/raster/pages/online-arbitrage/Study/screenshot@992.jpg";
import JPG_992_2x from "../../../assets/images/raster/pages/online-arbitrage/Study/screenshot@992_2x.jpg";
import JPG_mob from "../../../assets/images/raster/pages/online-arbitrage/Study/screenshot@mob.jpg";
import JPG_mob2x from "../../../assets/images/raster/pages/online-arbitrage/Study/screenshot@mob_2x.jpg";
import WEBP from "../../../assets/images/raster/pages/online-arbitrage/Study/screenshot.webp";
import WEBP_2x from "../../../assets/images/raster/pages/online-arbitrage/Study/screenshot@2x.webp";
import WEBP_1200 from "../../../assets/images/raster/pages/online-arbitrage/Study/screenshot@1200.webp";
import WEBP_1200_2x from "../../../assets/images/raster/pages/online-arbitrage/Study/screenshot@1200_2x.webp";
import WEBP_992 from "../../../assets/images/raster/pages/online-arbitrage/Study/screenshot@992.webp";
import WEBP_992_2x from "../../../assets/images/raster/pages/online-arbitrage/Study/screenshot@992_2x.webp";
import WEBP_mob from "../../../assets/images/raster/pages/online-arbitrage/Study/screenshot@mob.webp";
import WEBP_mob2x from "../../../assets/images/raster/pages/online-arbitrage/Study/screenshot@mob_2x.webp";

const Screenshot = () => {
  return (
    <div className="PgOA-Study-screen">
      <picture>
        <source
          type="image/webp"
          media="(min-width: 1366px)"
          srcSet={`${WEBP} 1x, ${WEBP_2x} 2x`}
        />
        <source
          media="(min-width: 1366px)"
          srcSet={`${JPG} 1x, ${JPG_2x} 2x`}
        />
        <source
          type="image/webp"
          media="(min-width: 1200px) and (max-width: 1365px)"
          srcSet={`${WEBP_1200} 1x, ${WEBP_1200_2x} 2x`}
        />
        <source
          media="(min-width: 1200px) and (max-width: 1365px)"
          srcSet={`${JPG_1200} 1x, ${JPG_1200_2x} 2x`}
        />
        <source
          type="image/webp"
          media="(min-width: 992) and (max-width: 1199px)"
          srcSet={`${WEBP_992} 1x, ${WEBP_992_2x} 2x`}
        />
        <source
          media="(min-width: 992px) and (max-width: 1199px)"
          srcSet={`${JPG_992} 1x, ${JPG_992_2x} 2x`}
        />
        <source
          type="image/webp"
          media="(min-width: 768) and (max-width: 991px)"
          srcSet={`${WEBP} 1x, ${WEBP_2x} 2x`}
        />
        <source
          media="(min-width: 768px) and (max-width: 991px)"
          srcSet={`${JPG} 1x, ${JPG_2x} 2x`}
        />
        <source
          type="image/webp"
          media="(max-width: 767px)"
          srcSet={`${WEBP_mob} 1x, ${WEBP_mob2x} 2x`}
        />
        <source
          media="(max-width: 767px)"
          srcSet={`${JPG_mob} 1x, ${JPG_mob2x} 2x`}
        />
        <img src={JPG} alt="" loading="lazy" />
      </picture>
    </div>
  );
};
export default Screenshot;
