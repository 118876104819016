import React from "react";
import STUDY_CONTENT from "./index.content";
import SVG_PointIcon from "../../../assets/images/vector/components/common/group-copy.svg";
import Items from "../Items";
import Screenshot from "./Screenshot";
import "./index.scss";

const Study = () => {
  const { listTexts } = STUDY_CONTENT;

  return (
    <section className="PgOA-Study">
      <div className="container">
        <div className="PgOA-Study-info">
          <div className="PgOA-Study-points">
            <div className="PgOA-Study-points__title">
              Review market trends to identify profitable products
            </div>
            <Items
              pic={SVG_PointIcon}
              listTexts={listTexts}
              customClasses="PgOA-Study-points__item"
            />
          </div>
          <Screenshot />
        </div>
      </div>
    </section>
  );
};
export default Study;
