import React, { useContext } from "react";
import { MediaAuthContext } from "../../../store/context/media-auth-context";
import { PAGES } from "../../../helpers/constants";
import { getTrueClasses } from "../../../helpers";
import HomeIntroPoints from "./HomeIntroPoints";
import StandardFormBlockContainer from "../../../containers/common/forms/StandardFormBlockContainer";
import HomeIntroScreen from "./HomeIntroScreen";
import "./index.scss";

const HomeIntro = () => {
  const {
    auth: { isAuth },
  } = useContext(MediaAuthContext);
  return (
    <section
      className={getTrueClasses(
        "PgOA-HomeIntro",
        isAuth && "PgOA-HomeIntro-secondState"
      )}
    >
      <div className="container">
        <h1 className="PgOA-HomeIntro__h1">
          Amazon Dropshipping, Arbitrage, and Wholesale Product Research
          Software
        </h1>
        <div className="PgOA-HomeIntro-row">
          <div className="PgOA-HomeIntro-info">
            <HomeIntroPoints />
            <div className="PgOA-HomeIntro__subTitle">
              Try Amazon Dropshipping, Arbitrage, and Wholesale Extension For
              Free Now
            </div>
            <StandardFormBlockContainer
              buttonTextFirstState="Try AMZScout For Free!"
              buttonTextSecondState="TRY FOR FREE NOW"
              page={PAGES.ONLINE_ARBITRAGE}
              customClass="PgOA-HomeIntro-emailCollect"
              placeholder="Enter your email to start your FREE TRIAL!"
              isIntro
            />
          </div>
          <HomeIntroScreen />
        </div>
      </div>
    </section>
  );
};

export default HomeIntro;
