import React from "react";
import FOCUS_CONTENT from "./index.content";
import Items from "../Items";
import "./index.scss";

const Focus = () => {
  const {
    firstListTexts,
    secondListTexts,
    SVG_OAIcon,
    SVG_AllowIcon,
    SVG_DisallowIcon,
  } = FOCUS_CONTENT;
  return (
    <section className="PgOA-Focus">
      <div className="container">
        <img
          className="PgOA-Focus-icon"
          src={SVG_OAIcon}
          alt=""
          loading="lazy"
        />
        <div className="PgOA-Focus__title">
          <div>Minimize your employee costs</div>
          and time to focus resources on scaling your business
        </div>
        <div className="PgOA-Focus-list">
          <Items
            pic={SVG_DisallowIcon}
            listTexts={firstListTexts}
            customClasses="PgOA-Focus-list__item"
          />
        </div>
        <div className="PgOA-Focus-list">
          <div className="PgOA-Focus-list__title">
            Amazon Dropshipping, Arbitrage, and Wholesale Software allows you
          </div>
          <Items
            pic={SVG_AllowIcon}
            listTexts={secondListTexts}
            customClasses="PgOA-Focus-list__item"
          />
        </div>
      </div>
    </section>
  );
};
export default Focus;
