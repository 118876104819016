import React from "react";

const Screen = ({ customClasses, screenshot }) => {
  const {
    JPG,
    JPG_2x,
    WEBP,
    WEBP_2x,
    JPG_992,
    JPG_992_2x,
    WEBP_992,
    WEBP_992_2x,
    JPG_mob,
    JPG_mob2x,
    WEBP_mob,
    WEBP_mob2x
  } = screenshot;
  return (
    <div className={customClasses}>
      <picture>
        <source
          type="image/webp"
          media="(min-width: 1200px)"
          srcSet={`${WEBP} 1x, ${WEBP_2x} 2x`}
        />
        <source
          media="(min-width: 1200px)"
          srcSet={`${JPG} 1x, ${JPG_2x} 2x`}
        />
        <source
          type="image/webp"
          media="(min-width: 768) and (max-width: 1199px)"
          srcSet={`${WEBP_992} 1x, ${WEBP_992_2x} 2x`}
        />
        <source
          media="(min-width: 768px) and (max-width: 1199px)"
          srcSet={`${JPG_992} 1x, ${JPG_992_2x} 2x`}
        />
        <source
          type="image/webp"
          media="(max-width: 767px)"
          srcSet={`${WEBP_mob} 1x, ${WEBP_mob2x} 2x`}
        />
        <source
          media="(max-width: 767px)"
          srcSet={`${JPG_mob} 1x, ${JPG_mob2x} 2x`}
        />
        <img src={JPG} alt="" loading="lazy" />
      </picture>
    </div>
  );
};
export default Screen;