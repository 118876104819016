import React from "react";
import PNG_Big from "../../../assets/images/raster/pages/online-arbitrage/HomeIntro/oa-intro-big.png";
import PNG_Big2x from "../../../assets/images/raster/pages/online-arbitrage/HomeIntro/oa-intro-big@2x.png";
import PNG from "../../../assets/images/raster/pages/online-arbitrage/HomeIntro/oa-intro.png";
import PNG_2x from "../../../assets/images/raster/pages/online-arbitrage/HomeIntro/oa-intro@2x.png";
import PNG_MD from "../../../assets/images/raster/pages/online-arbitrage/HomeIntro/oa-intro-md.png";
import PNG_MD2x from "../../../assets/images/raster/pages/online-arbitrage/HomeIntro/oa-intro-md@2x.png";
import PNG_Mob from "../../../assets/images/raster/pages/online-arbitrage/HomeIntro/oa-intro-mob.png";
import PNG_Mob2x from "../../../assets/images/raster/pages/online-arbitrage/HomeIntro/oa-intro-mob@2x.png";
import WEBP_Big from "../../../assets/images/raster/pages/online-arbitrage/HomeIntro/oa-intro-big.webp";
import WEBP_Big2x from "../../../assets/images/raster/pages/online-arbitrage/HomeIntro/oa-intro-big@2x.webp";
import WEBP from "../../../assets/images/raster/pages/online-arbitrage/HomeIntro/oa-intro.webp";
import WEBP_2x from "../../../assets/images/raster/pages/online-arbitrage/HomeIntro/oa-intro@2x.webp";
import WEBP_MD from "../../../assets/images/raster/pages/online-arbitrage/HomeIntro/oa-intro-md.webp";
import WEBP_MD2x from "../../../assets/images/raster/pages/online-arbitrage/HomeIntro/oa-intro-md@2x.webp";
import WEBP_Mob from "../../../assets/images/raster/pages/online-arbitrage/HomeIntro/oa-intro-mob.webp";
import WEBP_Mob2x from "../../../assets/images/raster/pages/online-arbitrage/HomeIntro/oa-intro-mob@2x.webp";
import SVG_BgIcon from "../../../assets/images/vector/pages/online-arbitrage/Focus/oa-icon.svg";
import SVG_PointIcon from "../../../assets/images/vector/components/common/group-copy.svg";

const PG_OA_HOME_INTRO_CONTENT = {
  screen: {
    PNG_Big,
    PNG_Big2x,
    PNG,
    PNG_2x,
    PNG_MD,
    PNG_MD2x,
    PNG_Mob,
    PNG_Mob2x,
    WEBP_Big,
    WEBP_Big2x,
    WEBP,
    WEBP_2x,
    WEBP_MD,
    WEBP_MD2x,
    WEBP_Mob,
    WEBP_Mob2x,
    SVG_BgIcon
  },
  points: {
    paragraphList: [
      "Check the minimum price across all sellers to see if you can win the buy box",
      "Check fees and calculate return on investment right on the Amazon product page",
      "Reduce x3-x5 your time for choosing products to sell, and free up time for doing business"
    ],
    SVG_PointIcon
  }
};
export default PG_OA_HOME_INTRO_CONTENT;