import React from "react";

const Items = ({ pic, listTexts, customClasses = "", keyMod = "" }) =>
  listTexts.map((text, i) => (
    <div key={keyMod + i} className={customClasses}>
      <img src={pic} alt="-" loading="lazy" />
      <div>{text}</div>
    </div>
  ));
export default Items;
