import React from "react";
import PNG from "../../../assets/images/raster/pages/online-arbitrage/Forecast/oa-sec3-bg.png";
import PNG_2x from "../../../assets/images/raster/pages/online-arbitrage/Forecast/oa-sec3-bg@2x.png";
import PNG_Mob from "../../../assets/images/raster/pages/online-arbitrage/Forecast/oa-sec3-bg@mob.png";
import PNG_Mob2x from "../../../assets/images/raster/pages/online-arbitrage/Forecast/oa-sec3-bg@mob2x.png";
import WEBP from "../../../assets/images/raster/pages/online-arbitrage/Forecast/oa-sec3-bg.webp";
import WEBP_2x from "../../../assets/images/raster/pages/online-arbitrage/Forecast/oa-sec3-bg@2x.webp";
import WEBP_Mob from "../../../assets/images/raster/pages/online-arbitrage/Forecast/oa-sec3-bg@mob.webp";
import WEBP_Mob2x from "../../../assets/images/raster/pages/online-arbitrage/Forecast/oa-sec3-bg@mob2x.webp";
import SVG_PointIcon from "../../../assets/images/vector/components/common/group-copy.svg";

const FORECAST_CONTENT = {
  screenshot: {
    WEBP,
    WEBP_2x,
    PNG,
    PNG_2x,
    WEBP_Mob,
    WEBP_Mob2x,
    PNG_Mob,
    PNG_Mob2x
  },
  SVG_PointIcon,
  listTexts: [
    <>Current <b>Buy box price</b></>,
    <>The <b>minimum price</b> for FBA or FBM sellers</>,
    <><b>Fees</b> that Amazon charges from your sales</>,
    <>The <b>Profit</b> and <b>Margin</b> you’ll make on the item</>,
    <><b>Return on your investment</b> right on the product page</>
  ]
};
export default FORECAST_CONTENT;
