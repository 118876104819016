import React from "react";
import SVG_AllowIcon from "../../../assets/images/vector/pages/online-arbitrage/Focus/allow-icon.svg";
import SVG_DisallowIcon from "../../../assets/images/vector/pages/online-arbitrage/Focus/disallow-icon.svg";
import SVG_OAIcon from "../../../assets/images/vector/pages/online-arbitrage/Focus/oa-icon.svg";

const FOCUS_CONTENT = {
  firstListTexts: [
    <><b>Spending countless hours</b> grinding on Amazon looking for new products?</>,
    <><b>Paying money to your assistants</b> to find a new product?</>,
    <><b>Have to analyze thousands of items</b> from your Wholesale supplier for profit margin and competition?</>,
  ],
  secondListTexts: [
    <><b>Access all of the data that you need</b> to select the most promising products directly on the Amazon product page</>,
    <><b>Reduces x3-x5 times product research</b> and labour intensity</>,
    <><b>Free up time for doing business,</b> and redirect your budget to scaling your sales with PPC ads</>,
  ],
  SVG_AllowIcon,
  SVG_DisallowIcon,
  SVG_OAIcon
};
export default FOCUS_CONTENT;
