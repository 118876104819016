import React from "react";
import FORECAST_CONTENT from "./index.content";
import Items from "../Items";
import Screenshot from "../Screenshot";
import "./index.scss";

const Forecast = () => {
  const { screenshot, SVG_PointIcon, listTexts } = FORECAST_CONTENT;
  return (
    <section className="PgOA-Forecast">
      <div className="container">
        <div className="PgOA-Forecast__title">
          Predict your potential product sales and profitability in seconds
        </div>
        <div className="PgOA-Forecast__subtitle">
          Get all of the data that you need to select the most promising
          products directly on the Amazon product page
        </div>
        <div className="PgOA-Forecast-info">
          <Screenshot
            customClasses="PgOA-Forecast-screen"
            listPictures={screenshot}
          />
          <div className="PgOA-Forecast-points">
            <Items
              pic={SVG_PointIcon}
              listTexts={listTexts}
              customClasses="PgOA-Forecast-points__item"
            />
          </div>
        </div>
      </div>
    </section>
  );
};
export default Forecast;
