import React from "react";
import PNG from "../../../assets/images/raster/pages/online-arbitrage/Success/oa-sec2-bg.png";
import PNG_2x from "../../../assets/images/raster/pages/online-arbitrage/Success/oa-sec2-bg@2x.png";
import PNG_Mob from "../../../assets/images/raster/pages/online-arbitrage/Success/oa-sec2-bg@mob.png";
import PNG_Mob2x from "../../../assets/images/raster/pages/online-arbitrage/Success/oa-sec2-bg@mob2x.png";
import WEBP from "../../../assets/images/raster/pages/online-arbitrage/Success/oa-sec2-bg.webp";
import WEBP_2x from "../../../assets/images/raster/pages/online-arbitrage/Success/oa-sec2-bg@2x.webp";
import WEBP_Mob from "../../../assets/images/raster/pages/online-arbitrage/Success/oa-sec2-bg@mob.webp";
import WEBP_Mob2x from "../../../assets/images/raster/pages/online-arbitrage/Success/oa-sec2-bg@mob2x.webp";
import SVG_PointIcon from "../../../assets/images/vector/components/common/group-copy.svg";

const SUCCESS_CONTENT = {
  screenshot: {
    WEBP,
    WEBP_2x,
    PNG,
    PNG_2x,
    WEBP_Mob,
    WEBP_Mob2x,
    PNG_Mob,
    PNG_Mob2x
  },
  SVG_PointIcon,
  listTexts: [
    "Product requires additional certificates to obtain approval to sell because it is in a gated category or it has hazardous materials",
    "Product needs certificates or extra approvals from Amazon because it has hazardous materials",
    "Product is sold by Amazon and the competition with Amazon for the buybox could significantly reduce your potential sales volume",
    "Copyright, trademark, or patent claims related to a registered trademark for this product",
    "Excluding products that need Prime delivery allows the seller not to focus on fast shipping",
    "If the product is a Private Label, sourcing is likely impossible for Dropshipping or Online Arbitrage",
  ]
};
export default SUCCESS_CONTENT;
