import React from "react";
import QUICK_PICK_CONTENT from "./index.content";
import "./index.scss";
import Items from "../Items";

const QuickPick = () => {
  const { listTexts, SVG_PointIcon } = QUICK_PICK_CONTENT;
  return (
    <section className="PgOA-QuickPick">
      <div className="container">
        <div className="PgOA-QuickPick__title">
          Pick out the most profitable products fast to cut costs and grow your
          business
        </div>
        <div className="PgOA-QuickPick-points">
          <Items
            pic={SVG_PointIcon}
            listTexts={listTexts}
            customClasses="PgOA-QuickPick-points__item"
          />
        </div>
      </div>
    </section>
  );
};
export default QuickPick;
