import React from "react";

const STUDY_CONTENT = {
  listTexts: [
    <><b>Monitor products’ rank</b> to see fluctuations in demand, predict market trends and select the most promising
      products for future</>,
    <><b>Review price history and fluctuations in demand</b> to predict marginality trends and develop the best
      repricing strategy</>
  ]
};
export default STUDY_CONTENT;
