import React, { useContext, useRef } from "react";
import { MediaAuthContext } from "../store/context/media-auth-context";
import OgImg from "../assets/images/raster/components/common/mainscreen_reverse_asin_lookup.png";
import { PAGES } from "../helpers/constants";
import Layout from "../layouts/LayoutDefault";
import Seo from "../components/Seo";
import HomeIntro from "../components/online-arbitrage/HomeIntro";
import QuickPick from "../components/online-arbitrage/QuickPick";
import Success from "../components/online-arbitrage/Success";
import Forecast from "../components/online-arbitrage/Forecast";
import Study from "../components/online-arbitrage/Study";
import Focus from "../components/online-arbitrage/Focus";
import Find from "../components/online-arbitrage/Find";
import CustomizableContainer from "../containers/Paywalls/Customizable/CustomizableContainer";
import FormBlockContainer from "../containers/common/forms/FormBlockContainer";
import "../components/online-arbitrage/index.scss";

const PgOA = () => {
  const {
    auth: { isAuth },
  } = useContext(MediaAuthContext);
  const tariffs = useRef(null);

  const formPropsData = {
    buttonTextFirstState: "Try AMZScout For Free!",
    buttonTextSecondState:
      "Try Amazon Dropshipping, Arbitrage, and Wholesale Extension for FREE",
    page: PAGES.ONLINE_ARBITRAGE,
  };
  return (
    <Layout
      customClass="PgOA"
      tariffsElement={tariffs}
    >
      <Seo
        title="Amazon Online Arbitrage and Dropshipping Software"
        description={
          "Software for online arbitrage sellers, dropshippers and wholesalers by AMZScout shows a product's reselling potential. Try the extension for free and find the most profitable product for your business."
        }
        page="online-arbitrage"
        manifest="browserconfig.xml"
        ogImg={OgImg}
      />
      <HomeIntro />
      <QuickPick />
      <Success />
      <Forecast />
      <FormBlockContainer
        title="Check product profitability with a free trial now!"
        customClass={
          isAuth
            ? "PgOA-FormBlock__secondState PgOA-FormBlock__secondState_one"
            : "PgOA-FormBlock_one"
        }
        {...formPropsData}
      />
      <Study />
      <CustomizableContainer
        pricingRef={tariffs}
        customClass="PgOA-Pricing"
        advancedBoosterPackDefault
        subTitlePro
      />
      <Focus />
      <Find />
      <FormBlockContainer
        title="Find profitable products for Dropshipping, Arbitrage, and Wholesale with free Chrome extension trial now!"
        customClass={
          isAuth
            ? "PgOA-FormBlock__secondState PgOA-FormBlock__secondState_two"
            : "PgOA-FormBlock_two"
        }
        {...formPropsData}
      />
    </Layout>
  );
};

export default PgOA;
