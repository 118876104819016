import React from "react";
import PG_OA_HOME_INTRO_CONTENT from "./index.content";

const HomeIntroScreen = () => {
  const {
    PNG_Big,
    PNG_Big2x,
    PNG,
    PNG_2x,
    PNG_MD,
    PNG_MD2x,
    PNG_Mob,
    PNG_Mob2x,
    WEBP_Big,
    WEBP_Big2x,
    WEBP,
    WEBP_2x,
    WEBP_MD,
    WEBP_MD2x,
    WEBP_Mob,
    WEBP_Mob2x,
    SVG_BgIcon,
  } = PG_OA_HOME_INTRO_CONTENT.screen;
  return (
    <div className="PgOA-HomeIntro-screen">
      <picture>
        <source
          type="image/webp"
          media="(min-width: 1366px)"
          srcSet={`${WEBP_Big} 1x, ${WEBP_Big2x} 2x`}
        />
        <source
          media="(min-width: 1366px)"
          srcSet={`${PNG_Big} 1x, ${PNG_Big2x} 2x`}
        />
        <source
          type="image/webp"
          media="(min-width: 1200px) and (max-width: 1366px)"
          srcSet={`${WEBP} 1x, ${WEBP_2x} 2x`}
        />
        <source
          media="(min-width: 1200px) and (max-width: 1366px)"
          srcSet={`${PNG} 1x, ${PNG_2x} 2x`}
        />
        <source
          type="image/webp"
          media="(min-width: 992px) and (max-width: 1199px)"
          srcSet={`${WEBP_Big} 1x, ${WEBP_Big2x} 2x`}
        />
        <source
          media="(min-width: 992px) and (max-width: 1199px)"
          srcSet={`${PNG_Big} 1x, ${PNG_Big2x} 2x`}
        />
        <source
          type="image/webp"
          media="(min-width: 768px) and (max-width: 991px)"
          srcSet={`${WEBP_MD} 1x, ${WEBP_MD2x} 2x`}
        />
        <source
          media="(min-width: 768px) and (max-width: 991px)"
          srcSet={`${PNG_MD} 1x, ${PNG_MD2x} 2x`}
        />
        <source
          type="image/webp"
          media="(max-width: 767px)"
          srcSet={`${WEBP_Mob} 1x, ${WEBP_Mob2x} 2x`}
        />
        <source
          media="(max-width: 767px)"
          srcSet={`${PNG_Mob} 1x, ${PNG_Mob2x} 2x`}
        />
        <img src={PNG} alt="" />
      </picture>
      <img
        className="PgOA-HomeIntro-icon"
        src={SVG_BgIcon}
        alt=""
        loading="lazy"
      />
    </div>
  );
};

export default HomeIntroScreen;
