import React from "react";
import SUCCESS_CONTENT from "./index.content";
import Items from "../Items";
import Screenshot from "../Screenshot";
import "./index.scss";

const Success = () => {
  const { screenshot, SVG_PointIcon, listTexts } = SUCCESS_CONTENT;

  return (
    <section className="PgOA-Success">
      <div className="container">
        <div className="PgOA-Success__title">
          Avoid risky products and maximize the success rate of your investment
        </div>
        <div className="PgOA-Success__subtitle">
          Minimize your investment risks by checking to see if the product needs
          special permissions or has other limitations for Dropshipping,
          Arbitrage or Wholesale
        </div>
        <div className="PgOA-Success-info">
          <div className="PgOA-Success-points">
            <div className="PgOA-Success-points__title">
              Eliminate risk factors such as:
            </div>
            <Items
              pic={SVG_PointIcon}
              listTexts={listTexts}
              customClasses="PgOA-Success-points__item"
            />
          </div>
          <Screenshot
            customClasses="PgOA-Success-screen"
            listPictures={screenshot}
          />
        </div>
      </div>
    </section>
  );
};
export default Success;
